@use '@/styles/utils/mixins.scss' as *;

.technologyRiskAssessmentWraper {
  max-width: 52.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-bottom: 3.75rem;
  margin-top: 3.75rem;

  .vulnerabilityScanForm {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    font-size: 0.875rem;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
      .float-label {
        .as-label,
        .ant-select-selector,
        .float-input {
          background: $neutral01 !important;
        }
      }
    }

    .ipDiscoverySection {
      max-width: 27.5rem;
    }
    .radioGroup {
      display: flex;
      gap: 0.75rem;
      margin-top: 0.75rem;
      :global {
        .ant-radio-wrapper {
          width: calc(50% - 0.75rem);
        }
      }
    }
  }
}
